@font-face {
  font-family: 'Avenir';
  font-weight: normal;
  src: local('Avenir 45 Book'), url('./fonts/avenir-45-book/avenir-45-book.woff2') format('woff2'),
    url('./fonts/avenir-45-book/avenir-45-book.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-weight: bold;
  src: local('Avenir 85 Heavy'),
    url('./fonts/avenir-85-heavy/avenir-85-heavy.woff2') format('woff2'),
    url('./fonts/avenir-85-heavy/avenir-85-heavy.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Avenir', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

a {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiAvatar-root {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
